import { HTMLAttributes, ReactNode } from 'react';

import { cn } from 'utils/tailwind';

export type HelpTextProps = HTMLAttributes<HTMLDivElement> & {
  error?: boolean;
  children: ReactNode;
};

export const HelpText = ({
  error,
  children,
  className,
  ...props
}: HelpTextProps) => {
  // Children is coalesced with a zero width character to prevent
  // layout shifts in forms when help/error text is not present.
  return (
    <div
      className={cn(className, 'text-sm', {
        'text-error dark:text-error-light': error,
        'text-current': !error,
      })}
      {...props}
    >
      {children || '\u200b'}
    </div>
  );
};
