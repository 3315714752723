import {
  useCallback,
  type ChangeEventHandler,
  type ReactNode,
  type Ref,
  type SelectHTMLAttributes,
} from 'react';

import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import { IconContainer, EditableStyle } from '../shared';

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> &
  BaseProps & {
    ref?: Ref<HTMLSelectElement>;
    icon?: ReactNode;
    onValueChange?: (value: string) => unknown;
    error?: boolean;
  };

export const Select = ({
  icon,
  className,
  onValueChange,
  onChange,
  error = false,
  ...props
}: SelectProps) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  return (
    <div className="relative">
      {icon && <IconContainer alignment="left">{icon}</IconContainer>}
      <EditableStyle
        className={cn(className, 'pr-8', {
          'pr-10': error,
          'pl-10': Boolean(icon),
        })}
      >
        <select
          className={cn('truncate', { '!border-error': error })}
          {...props}
          onChange={handleChange}
        />
      </EditableStyle>
      <IconContainer
        alignment="right"
        className="pointer-events-none -mr-1 text-typography peer-disabled:text-disabled dark:text-white dark:peer-disabled:text-disabled"
      >
        <svg
          fill="none"
          height="12"
          viewBox="0 0 12 12"
          width="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7943 4.02796L6.48601 9.114C6.30823 9.264 6.14157 9.32511 5.9999 9.32511C5.85824 9.32511 5.66741 9.26347 5.53908 9.13934L0.205812 4.02796C-0.0607405 3.77518 -0.069407 3.32797 0.185812 3.0863C0.439281 2.81895 0.863025 2.81025 1.12774 3.06634L5.9999 7.73624L10.8721 3.06964C11.1359 2.81356 11.5604 2.82225 11.814 3.0896C12.0693 3.32797 12.0609 3.77518 11.7943 4.02796Z"
            fill="currentColor"
          />
        </svg>
      </IconContainer>
    </div>
  );
};
