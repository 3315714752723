import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import type { LabelHTMLAttributes } from 'react';

export type LabelProps = BaseProps & LabelHTMLAttributes<HTMLLabelElement>;

export const Label = ({
  className,
  invertTheme,
  htmlFor,
  ...props
}: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      {...props}
      className={cn(className, 'text-base font-normal text-current', {
        'theme-invert': invertTheme,
      })}
    />
  );
};
