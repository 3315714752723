import { ReactNode } from 'react';

import { cn } from 'utils/tailwind';

interface IconContainerProps {
  children?: ReactNode;
  className?: string;
  alignment: 'left' | 'right';
}

export const IconContainer = ({
  children,
  className,
  alignment,
}: IconContainerProps) => {
  if (!children) {
    return null;
  }

  return (
    <span
      className={cn(
        'absolute top-0 flex h-11 items-center justify-center',
        { 'left-4': alignment === 'left', 'right-4': alignment === 'right' },
        className
      )}
    >
      {children}
    </span>
  );
};
