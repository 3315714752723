import { Slot } from '@radix-ui/react-slot';

import { cn } from 'utils/tailwind';

import type { ReactNode } from 'react';

export interface EditableStyleProps {
  children: ReactNode;
  className?: string;
}

export const EditableStyle = ({ children, className }: EditableStyleProps) => {
  return (
    <Slot
      className={cn(
        'peer form-input block h-11 w-full rounded border border-island-900 bg-white pr-16 text-island-900 outline-none only:pl-4 dark:border-white dark:bg-island-800 dark:text-white',
        'placeholder:text-island-900/60 disabled:placeholder:text-disabled/60 dark:placeholder:text-white/60 dark:disabled:placeholder:text-disabled/60',
        'focus-visible:border-island focus-visible:ring-focus focus-visible:ring-offset-2',
        'invalid:[&:not(:focus)]:border-error dark:invalid:[&:not(:focus)]:border-error-light invalid:[&:not(:placeholder-shown):not(:focus)]:pr-16',
        'data-touched:required:invalid:[&:not(:focus)]:border-error data-touched:required:invalid:[&:not(:focus)]:pr-16 dark:data-touched:required:invalid:[&:not(:focus)]:border-error-light',
        'disabled:border-disabled disabled:bg-disabled-light disabled:text-disabled dark:disabled:border-disabled dark:disabled:bg-disabled-dark dark:disabled:text-disabled',
        className
      )}
    >
      {children}
    </Slot>
  );
};
