import {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  type ChangeEventHandler,
  type InputHTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  type Ref,
} from 'react';

import { Button } from 'components/Buttons';
import { CloseIcon } from 'components/Icons';
import { BaseProps } from 'components/types';
import { setNativeValue } from 'utils/react';
import { cn } from 'utils/tailwind';

import { IconContainer } from '../shared';
import { EditableStyle } from '../shared/EditableStyle';

type InputRef = HTMLInputElement | null;

export type InputProps = BaseProps &
  InputHTMLAttributes<HTMLInputElement> & {
    'ref'?: Ref<HTMLInputElement>;
    'onValueChange'?: (value: string) => unknown;
    'onClear'?: () => void;
    'icon'?: ReactNode;
    'inputClassName'?: string;
    'showClearButton'?: 'auto' | 'whenValue';
    'data-testid'?: string;
  };

export const Input = ({
  ref,
  icon,
  className,
  placeholder,
  invertTheme,
  onChange,
  onValueChange,
  onClear,
  inputClassName,
  showClearButton = 'auto',
  ...props
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = useState(false);

  useImperativeHandle<InputRef, InputRef>(ref, () => inputRef.current);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (inputRef.current) {
      e.preventDefault();
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(
        new InputEvent('change', { bubbles: true })
      );
      onClear?.();
    }
  };

  return (
    <div
      className={cn('relative', className, {
        'theme-invert': invertTheme,
        'text-disabled': props.disabled,
        'text-typography dark:text-white': !props.disabled,
      })}
    >
      <EditableStyle
        className={cn({
          'pl-10': Boolean(icon),
          'pr-16': props.type === 'password',
        })}
      >
        <input
          {...props}
          onChange={handleChange}
          placeholder={placeholder ?? '\u200b'}
          ref={inputRef}
          type={props.type === 'password' && showPassword ? 'text' : props.type}
          className={cn(
            {
              'appearance-none': props.type === 'number',
            },
            inputClassName
          )}
        />
      </EditableStyle>
      {icon && <IconContainer alignment="left">{icon}</IconContainer>}
      {props.type === 'password' ? (
        <Button
          className="absolute right-4 top-0 flex h-11 items-center justify-center"
          onClick={() => setShowPassword((prev) => !prev)}
          type="button"
          variant="text"
        >
          {showPassword ? 'Hide' : 'Show'}
        </Button>
      ) : (
        <IconContainer
          alignment="right"
          className={cn('text-current', {
            'peer-placeholder-shown:hidden':
              showClearButton === 'whenValue' || showClearButton === 'auto',
            'peer-not-focus:hidden': showClearButton === 'auto',
          })}
        >
          <button
            aria-label="Clear"
            className="-mr-2 appearance-none p-2"
            onClick={handleClear}
            onMouseDown={(e) => e.preventDefault()}
            type="button"
          >
            <CloseIcon size="0.75rem" />
          </button>
        </IconContainer>
      )}
    </div>
  );
};
