import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  type HTMLAttributes,
  type Ref,
} from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { Button } from 'components/Buttons';
import { MinusIcon, PlusIcon } from 'components/Icons';
import { type BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import { EditableStyle } from '../shared';

type InputRef = HTMLInputElement | null;

export interface NumberInputProps
  extends BaseProps,
    Omit<NumericFormatProps, 'value' | 'onValueChange'> {
  ref?: Ref<HTMLInputElement>;
  step?: number;
  thousandSeparator?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: number;
  min?: number;
  max?: number;
  value?: number; // Controlled value
  suffix?: string;
  prefix?: string;
  onValueChange?: (value: number | undefined) => void;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  inputClassName?: string;
}

type InnerNumberInputProps = HTMLAttributes<HTMLInputElement> & {
  ref?: Ref<HTMLInputElement>;
};

const InnerNumberInput = (props: InnerNumberInputProps) => (
  <EditableStyle className="px-4">
    <input {...props} />
  </EditableStyle>
);

export const NumberInput = ({
  ref,
  step = 1,
  thousandSeparator,
  className,
  inputClassName,
  invertTheme,
  placeholder,
  disabled,
  defaultValue,
  min = -Infinity,
  max = Infinity,
  onValueChange,
  fixedDecimalScale = false,
  decimalScale = 0,
  suffix,
  prefix,
  value: controlledValue,
  ...props
}: NumberInputProps) => {
  const internalRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<InputRef, InputRef>(ref, () => internalRef.current);

  const [value, setValue] = useState<number | undefined>(
    typeof controlledValue === 'number'
      ? controlledValue
      : typeof defaultValue === 'number'
        ? defaultValue
        : undefined
  );

  const handleIncrement = useCallback(
    () =>
      setValue((prev) =>
        prev === undefined ? step : Math.min(prev + step, max)
      ),
    [step, max]
  );

  const handleDecrement = useCallback(
    () =>
      setValue((prev) =>
        prev === undefined ? -step : Math.max(prev - step, min)
      ),
    [step, min]
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (document.activeElement === internalRef.current) {
        if (e.key === 'ArrowUp') {
          handleIncrement();
        } else if (e.key === 'ArrowDown') {
          handleDecrement();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleIncrement, handleDecrement]);

  useEffect(() => {
    if (controlledValue !== undefined) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  const handleChange = (values: {
    value: string;
    floatValue: number | undefined;
  }) => {
    const newValue =
      values.floatValue === undefined ? undefined : values.floatValue;
    setValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const handleBlur = () => {
    if (value !== undefined) {
      if (value < min) {
        setValue(min);
        internalRef.current!.value = String(min);
      } else if (value > max) {
        setValue(max);
        internalRef.current!.value = String(max);
      }
    }
  };

  return (
    <div
      className={cn('flex items-center gap-2', className, {
        'theme-invert': invertTheme,
        'text-disabled': disabled,
        'text-typography dark:text-white': !disabled,
      })}
    >
      <Button
        aria-label="Decrease value"
        className="border-island-dark bg-transparent p-2 text-island-dark hover:border-island-700 hover:bg-island-100 hover:text-island-700 focus-visible:border-island-dark focus-visible:bg-transparent focus-visible:text-island-dark active:border-island-400 active:bg-transparent active:text-island-400 disabled:border-disabled-light disabled:bg-transparent disabled:text-disabled-light data-loading:border-island-400 data-loading:bg-transparent data-loading:text-island-400"
        disabled={disabled || (typeof value === 'number' && value <= min)}
        onClick={handleDecrement}
        type="button"
        variant="outline"
      >
        <MinusIcon className="size-3" />
      </Button>
      <EditableStyle className="px-4">
        <NumericFormat
          valueIsNumericString
          allowNegative={min < 0}
          customInput={InnerNumberInput}
          decimalScale={decimalScale}
          disabled={disabled}
          fixedDecimalScale={fixedDecimalScale}
          getInputRef={internalRef}
          inputMode={decimalScale === 0 ? 'numeric' : 'decimal'}
          max={max}
          min={min}
          onBlur={handleBlur}
          onValueChange={handleChange}
          placeholder={placeholder ?? '\u200b'}
          prefix={prefix}
          step={step}
          suffix={suffix}
          thousandSeparator={thousandSeparator}
          value={value}
          className={cn(
            '!w-24 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
            inputClassName
          )}
          {...props}
        />
      </EditableStyle>
      <Button
        aria-label="Increase value"
        className="border-island-dark bg-transparent p-2 text-island-dark hover:border-island-700 hover:bg-island-100 hover:text-island-700 focus-visible:border-island-dark focus-visible:bg-transparent focus-visible:text-island-dark active:border-island-400 active:bg-transparent active:text-island-400 disabled:border-disabled-light disabled:bg-transparent disabled:text-disabled-light data-loading:border-island-400 data-loading:bg-transparent data-loading:text-island-400"
        disabled={disabled || (typeof value === 'number' && value >= max)}
        onClick={handleIncrement}
        type="button"
        variant="outline"
      >
        <PlusIcon className="size-3" />
      </Button>
    </div>
  );
};
